<template>
  <div class="allPage">
    <div class="headbox">
      <heads :msg="title"></heads>
    </div>
    <ul class="main_title main_title_width_dengyu_1">
      <li v-for="(val, key) in zblist" :key="key" class="morelive_item morelive_item_width_dengyu_1">
        <a href="javascript:void(0);" class="live_item morelive_item_width_dengyu_1" @click="go_room(val)">
          <div class="live_left">
            <img :src="val.teacher_avatar">
            <span class="teacher_name">{{ val.teacher_name }}</span>
          </div>
          <div class="live_right">
            <div class="div_content">
              <p class="more-title">{{ val.live_name }}</p>
              <p class="sub_title">{{ val.lesson_name }}</p>
            </div>
            <p v-if="parseInt(val.playing) === 1" class="p">
              <img src="@/assets/play.gif">
              <span class="sp_iv" style="color: #ee2e2e">正在直播</span>
              <span class="enter_live">进入直播</span>
            </p>
            <p v-else class="more-yugao">
              <img src="../../icon/zbyg.png" class="imgsnz">
              <span class="sp_iv">直播预告&nbsp;&nbsp;{{ val.start_time_m }}</span>
            </p>
          </div>
        </a>
      </li>
    </ul>
  </div>
</template>
<script>
import { getzhibo } from '@/utils/index.js'
import tool from '@/utils/tools.js'
import heads from '@/components/common/heads.vue'
import Cookie from 'js-cookie'
export default {
  components: {
    heads
  },
  data() {
    return {
      title: '全部直播',
      zblist: [],
      c_id: Cookie.get('fid') ? Cookie.get('fid') : '',
      vip_type: ''
    }
  },
  created() {
    if (this.$route.query.vip) {
      const vipLive = this.$route.query.vip
      if (vipLive === '1') {
        this.vip_type = '1'
      }
    }
    this.get_zhibo()
  },
  methods: {
    go_room(item) {
      if (tool.isInApp()) {
        if (item.playing == 1) {
          const lesson_id = item.lesson_id ? item.lesson_id : ''
          const live_id = item.id ? item.id : ''
          const wk_id = item.wk_id ? item.wk_id : ''
          const livePath = 'lesson_id=' + lesson_id + '&live_id=' + live_id + '&wk_id=' + wk_id
          if (tool.isInIosApp()) {
            window.webkit.messageHandlers.vue_openLive.postMessage(livePath)
          } else {
            this.$jsbridge.callHandler('vue_openLive', livePath, function(responseData) {
              // android 返参数接受
            })
          }
        } else {
          // 课程详情
          if (tool.isInIosApp()) {
            window.webkit.messageHandlers.vue_openLesson.postMessage(item.view_url)
          } else {
            this.$jsbridge.callHandler('vue_openLesson', item.view_url, function(responseData) {
              // android 返参数接受
            })
          }
        }
      } else {
        window.location.href = item.view_url
      }
    },
    get_zhibo() {
      var params = {
        // category_id: this.c_id,
        pageNum: 1,
        pageSize: 10000
      }
      if (this.vip_type) {
        params.type = '1'
      }
      getzhibo(params).then((res) => {
        console.log(res.result.list)
        const lives = res.result.list
        this.zblist = lives
      })
    },

    go_back() {
      if (tool.isInApp()) {
        if (tool.isIosApp()) {
          window.webkit.messageHandlers.vue_backApp.postMessage('app')
        } else {
          this.$jsbridge.callHandler('vue_backApp', '直播专区返回', function(responseData) {
            // android 返参数接受
          })
        }
      } else {
        this.$router.go(-1)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.headbox {
  width: 100%;
  position: fixed;
  z-index: 1;
}
.allPage {
  // width: 100%;
  // height: 100vh;
  // overflow-y: scroll;
  background-color: #f5f5f5;
}
.main_right {
  .bottom_tip {
    position: absolute;
    bottom: 28px;
    width: 400px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .time {
      margin-left: 8px;
      display: inline-block;
      color: #909090;
    }
    .alarm_clock {
      width: 26px;
      height: 26px;
      margin-bottom: 1px;
    }
  }
  height: 189px;
  position: relative;
}

.main_title {
  overflow-x: hidden;
  font-size: 30px;
  color: #898888;
  letter-spacing: 1px;
}
.my_header {
  width: 100%;
  height: 95px;
  background: #2a292e;
}
.arrow {
  width: 28px;
  height: 48px;
  margin: 24px 0 0px 30px;
  float: left;
}
.title {
  display: inline-block;
  color: #fff;
  margin: 0 auto;
  line-height: 95px;
  // text-align: center;
  padding-left: 250px;
  letter-spacing: 2px;
  font-size: 38px;
}
.main {
  width: 100%;
  height: 189px;
  border-bottom: 1px solid #eaeaea;
  display: flex;
  background: #ffffff;
}
.main_left {
  width: 186px;
  height: 189px;
  .img1 {
    margin: 34px 0 0px 32px;
  }
}

.main_title_width {
  padding: 0 34px 23px 0;
}
.main_title_width_dengyu_1 {
  padding-top: 80px;
  width: 100%;
  padding-bottom: 34px;
  padding: 80px 20px 0;
  width: calc(100% - 40px);
  background-color: #f5f5f5;
}
.main_title {
  height: 100%;
  align-items: center;
  flex-shrink: 0;
  .morelive_item_width_dengyu_1 {
    border-radius: 16px;
    margin-top: 30px;
    background-color: #fff;
  }

  .morelive_item_width:last-child {
    margin-left: 34px;
  }

  .morelive_item {
    height: 220px;
    .live_item {
      height: 100%;
      display: flex;

      .live_left {
        padding-top: 5px;
        padding-bottom: 40px;
        margin-top: 10px;
        margin-right: 10px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        flex-direction: column;

        .teacher_name {
          width: 130px;
          padding-left: 12px;
          text-align: center;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 24px;
          color: #999999;
        }

        & > img {
          width: 79px;
          height: 79px;
          // display: inline-block;
          overflow: hidden;
          border-radius: 50%;
          margin: 10px 30px 3px 30px;
          border: 0 solid #e6e6e6;
        }
      }

      .live_right {
        height: 184px;
        width: 100%;
        padding-top: 28px;
        padding-bottom: 10px;
        display: flex;
        padding-right: 30px;
        position: relative;
        flex-direction: column;
        .div_content {
          width: 100%;
        }

        .p {
          display: flex;
          position: absolute;
          bottom: 0px;
          font-size: 24px;
          margin-bottom: 24px;
          align-items: center;
          .sp_iv {
            margin-top: 1px;
          }
          img {
            width: 24px;
            height: 24px;
          }

          .enter_live {
            background-color: #ee2e2e;
            display: inline-block;
            color: #fff;
            padding: 2px 10px;
            border-radius: 20px;
            font-size: 24px;
            margin-left: 8px;
          }
        }
      }
    }

    p {
      & > img {
        width: 16px;
        vertical-align: sub;
        margin-right: 6px;
      }
    }

    &:nth-of-type(3n) {
      margin-right: 0;
    }

    .more-title {
      // height: 70px;
      // padding-top: 23px;
      font-size: 28px;
      color: #333333;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      white-space: pre-wrap;

      &:hover {
        color: #ee2e2e;
      }
    }

    .sub_title {
      font-size: 26px;
      color: #999999;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
      margin-top: 12px;
      white-space: pre-wrap;

      // margin-bottom:20px;
    }

    .more-yugao {
      color: #adadad;
      margin-bottom: 12px;
      display: flex;
      align-items: center;
      margin-top: 12px;
      font-size: 24px;
      img {
        width: 32px;
        height: 32px;
      }
      .sp_iv {
        display: inline-block;
        margin-top: 4px;
      }
    }
  }
}
</style>
