<template>
  <div class="more_box">
    <div class="more_main">
      <!-- <span class="sanjiao"></span> -->
      <div
        v-for="(item,index) in navArr"
        :key="index"
        class="more_item"
        @click="goPage(item,index)"
      >
        <img :src="item.thumb" alt />
        <span id="span">{{ item.name }}</span>
        <div class="lines"></div>
      </div>
    </div>
  </div>
</template>

<script>
import Cookie from 'js-cookie'
import { getAppCommon } from '@/api/hometop.js'
import tool from '@/utils/tools.js'
export default {
  data () {
    return {
      isShow: false,
      navArr: [],
      isshow: false
    }
  },
  mounted () {

  },
  created () {
    this.agency_id = Cookie.get('h5_agency_id')
    this.app_id = Cookie.get('h5_app_id')
    this.getmenu()
  },
  methods: {
    getmenu () {
      var info = {
        app_id: this.app_id,
        agency_id: this.agency_id
      }
      getAppCommon(info).then(res => {
        console.log(res)
        this.navArr = res.result.top
      })
    },
    showMore () {
      this.isShow = !this.isShow
    },
    // 菜单跳转
    goPage (item, index) {
      if (tool.isInApp()) {
        this.openAppTab(index)
      } else {
        if (item.path === '') {
          window.location.href = item.url
        } else {
          this.$router.push({
            path: item.path
          })
        }
      }
    },
    openAppTab (index) {
      // 针对app的处理
      if (tool.isInIosApp()) {
        window.webkit.messageHandlers.vue_openTabbar.postMessage(index)
      } else {
        this.$jsbridge.callHandler(
          'vue_openTabbar'
          , index
          , function (responseData) {
            // android 返参数接受
          }
        )
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.more_box {
  height: 346px;
  width: 228px;
  position: fixed;
  top: 100px;
  right: 20px;
  z-index: 1;
}
#span {
  padding: 25px 0 25px 0px;
  position: relative;
  left: 30px;
}
.lines {
  border-bottom: 1px solid #ddd;
  width: 100%;
}
.more_main1 {
  display: none;
  position: absolute;
  right: 0;
  width: 228px;
  // height: 320px;
  box-sizing: border-box;
  margin-left: 20px;
  margin-top: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 0px 5px 4px #f0f0f0;
  padding-left: 30px;
  // position: relative;
  .more_item {
    font-size: 26px;
    text-align: left;
    img {
      width: 30px;
      height: 30px;
      margin-right: 20px;
      vertical-align: text-bottom;
    }
    span {
      // display: inline-block;
      padding: 25px 0;
      width: 140px;
    }
    .more_item04 {
      border-bottom: none;
    }
  }
}
/deep/.more_main {
  position: absolute;
  right: 0;
  width: 228px;
  // height: 320px;
  box-sizing: border-box;
  margin-left: 20px;
  margin-top: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 0px 5px 4px #f0f0f0;
  padding-left: 30px;
  // position: relative;
  .more_item {
    font-size: 26px;
    text-align: left;
    // border-bottom: 1px solid #ddd;
    img {
      width: 30px;
      height: 30px;
      margin-right: 20px;
      vertical-align: text-bottom;
    }
    span {
      display: inline-block;
      padding: 25px 0;
      // border-bottom: 1px solid #ddd;
      width: 140px;
    }
    .more_item04 {
      border-bottom: none;
    }
  }
}
// .sanjiao{
//      width:0;
//     height:0;
//     border:15px solid;
//     /* 单个三角形 */
//     border-color:transparent transparent #fff transparent;
//     position: absolute;
//     top:-30px;
//     left: 50%;
//     margin-left: -15px;
// }
</style>
