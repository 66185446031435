<template>
  <div>
    <div :class="heads">
      <img class="go_back" src="@/icon/back_black.png" alt @click="go_back()">
      <p :class="h2s">{{ msg }}</p>
      <img
        v-if="this.more&&this.isNotApp"
        class="mores"
        src="@/icon/mores.png"
        @click="clickMore"
      >
      <div v-else class="empty_div" />

      <moreMenus v-show="showMoreMenu"></moreMenus>
    </div>
  </div>
</template>

<script>
import tool from '@/utils/tools.js'
import moreMenus from '@/components/common/menu.vue'
// src/components/common/menu.vue
export default {
  components: {
    moreMenus
  },
  props: ['msg', 'states', 'more', 'address_management', 'address_management1', 'address_management2', 'backs'],
  data() {
    return {
      heads: 'heads_fff',
      h2s: 'h2_black',
      showMoreMenu: false,
      isNotApp: true
    }
  },
  mounted() {
    if (tool.isInApp()) {
      this.isNotApp = false
    } else {
      this.isNotApp = true``
    }
  },
  methods: {
    clickMore() {
      console.log('点击更多按钮了')
      this.showMoreMenu = !this.showMoreMenu
    },
    go_back() {
      if (tool.isInApp()) {
        if (tool.isInIosApp()) {
          window.webkit.messageHandlers.vue_backApp.postMessage('app')
        } else {
          this.$jsbridge.callHandler(
            'vue_backApp'
            , '返回'
            , function(responseData) {
              // android 返参数接受
            }
          )
        }
      } else {
        this.$router.go(-1)
      }
    },
    go_address_management() {
      // 快递》》》》地址管理
      this.$router.push({ path: '/index/express/address_management', query: { backs: 1 }})
    },
    go_add_address() {
      // 快递》》》》地址管理>>>>>添加地址
      this.$router.push({ path: '/index/express/add_address' })
    },
    go_add_addressJd() {
      this.$router.push({ path: '/addAddress' })
    }
  }
}
</script>

<style lang="scss" scoped>
.address_management {
  font-size: 25px;
  position: absolute;
  right: 32px;
  bottom: 31px;
}
.heads {
  width: 100%;
  height: 88px;
  background: #2a292e;
  position: relative;
}
.empty_div{
  width: 24px;
  height: 45px;
  margin-right: 30px;

}
.heads_fff {
  display: flex;
  align-items: center;
  width: 100%;
  height: 88px;
  background: #ffffff;
  position: relative;
  border-bottom: 1px solid #eee;
  .go_back {
    width: 68px;
    height: 68px;
    margin-left: 20px;
  }
  .mores {
    width: 40px;
    height: 36px;
    margin-right: 30px;

  }
  .h2_black {
    color: #353535;
    margin: 0 auto;
    text-align: center;
    font-weight: 500;
    font-size: 36px;
    height: 88px;
    line-height: 92px;
  }
}

.h2 {
  color: #fefeff;
  margin: 0 auto;
  text-align: center;
  font-weight: 500;
  line-height: 120px;
}

</style>
